import React, { useContext } from 'react';
import './navigation.css';
import logo from '../../res/frame/LogoDark.min.svg';
import { UserContextType } from '../../types';
import { UserContext } from '../../constants';

const Navigation = () => {
  const { user } = useContext(UserContext) as UserContextType;

  let favorites = <></>;
  if (user.favorites.length > 0) {
    favorites = (
      <>
        {user.favorites.map((f) => (
          <a key={f} className='menuEntry' href={'/' + f}>
            {f.charAt(0).toUpperCase() + f.slice(1)}
          </a>
        ))}
      </>
    );
  }

  let adminNav = <></>;
  if (user.isAdmin) {
    adminNav = (
      <>
        <a className='menuEntry' href='/stash'>
          Stash
        </a>
        <a className='menuEntry' href='/users'>
          Users
        </a>
      </>
    );
  }
  let login = (
    <a className='menuEntry' href='/login'>
      <p>Login</p>
    </a>
  );
  if (user.username != null) {
    /*const avatarURL = '/Avatars/' + user.username + '.png';
    <img src={avatarURL} alt='avatar' id='avatar' />*/
    login = (
      <div>
        <a className='menuEntry' href='/profile'>
          <p>{user.username}</p>
        </a>
      </div>
    );
  }

  return (
    <div>
      <nav>
        <a className='menuEntry' href='/'>
          <img src={logo} alt='logo' />
        </a>
        <a className='menuEntry' href='/projects'>
          Projects
        </a>
        {favorites}
        {adminNav}
        {login}
      </nav>
      <button id='menuToggle' onClick={navToggle}>
        <div className='bar1'></div>
        <div className='bar2'></div>
        <div className='bar3'></div>
      </button>
    </div>
  );

  function navToggle() {
    const button: HTMLElement = document.getElementById('menuToggle') as HTMLElement;
    button.classList.toggle('change');
    const menu: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('menuEntry') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < menu.length; i++) {
      const menuEntry: HTMLElement = menu[i];
      if (menuEntry.style.display === 'block') {
        menuEntry.style.display = 'none';
      } else {
        menuEntry.style.display = 'block';
      }
    }
  }
};
export default Navigation;
